.Select {
    position: relative;
}
.Select.is-disabled > .Select-control {
    background-color: #ffffff;
}
.Select.is-disabled > .Select-control:hover {
    box-shadow: none;
    border: 1px solid #cccccc !important;
}
.Select.is-disabled .Select-arrow-zone {
    cursor: default;
    pointer-events: none;
}
.Select-control {
    color: #333333;
    cursor: default;
    width: 100%;
    overflow: hidden;
    display: table;
    position: relative;
    border: 1px solid #cccccc;
    border-radius: 4px;
    height: 56px;
    width: 100%;
}
.Select-control:hover {
    border: 1px solid;
    border-radius: 4px;
}
.is-searchable.is-open > .Select-control {
    cursor: text;
    border: 2px solid;
}
.is-open > .Select-control > .Select-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
    color: #333;
}
.is-open > .Select-control > .Select-clear-zone {
    color: #333;
}
.is-searchable.is-focused:not(.is-open) > .Select-control {
    cursor: text;
}
.has-value:not(.Select--multi) > .Select-control > .Select-value .Select-value-label,
.has-value.is-pseudo-focused:not(.Select--multi)
    > .Select-control
    > .Select-value
    .Select-value-label {
    color: #333;
}
.has-value:not(.Select--multi) > .Select-control > .Select-value a.Select-value-label,
.has-value.is-pseudo-focused:not(.Select--multi)
    > .Select-control
    > .Select-value
    a.Select-value-label {
    cursor: pointer;
    text-decoration: none;
}
.has-value:not(.Select--multi) > .Select-control > .Select-value a.Select-value-label:hover,
.has-value.is-pseudo-focused:not(.Select--multi)
    > .Select-control
    > .Select-value
    a.Select-value-label:hover,
.has-value:not(.Select--multi) > .Select-control > .Select-value a.Select-value-label:focus,
.has-value.is-pseudo-focused:not(.Select--multi)
    > .Select-control
    > .Select-value
    a.Select-value-label:focus {
    outline: none;
    text-decoration: underline;
}
.Select-placeholder,
:not(.Select--multi) > .Select-control .Select-value {
    line-height: 54px;
    font-size: 16px;
    position: absolute;
    padding-left: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.Select.is-disabled > .Select-control .Select-value {
    color: hsl(0, 0%, 60%) !important;
}
.Select-input {
    width: 100%;
    padding-left: 10px;
    display: table;
}
.Select-input > input {
    width: 100%;
    background: none transparent;
    border: 0 none;
    box-shadow: none;
    cursor: default;
    display: table;
    line-height: 52px;
    font-size: 16px;
    font-family: 'Noto Sans';
    outline: none;
    -webkit-appearance: none;
}
.is-focused .Select-input > input {
    cursor: text;
}
.has-value.is-pseudo-focused .Select-input {
    opacity: 0;
}
.Select-control:not(.is-searchable) > .Select-input {
    outline: none;
}
.Select-loading-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 16px;
}
.Select-loading {
    -webkit-animation: Select-animation-spin 400ms infinite linear;
    -o-animation: Select-animation-spin 400ms infinite linear;
    animation: Select-animation-spin 400ms infinite linear;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-right-color: #333;
    display: inline-block;
    position: relative;
    vertical-align: middle;
}
.Select-clear-zone {
    -webkit-animation: Select-animation-fadeIn 200ms;
    -o-animation: Select-animation-fadeIn 200ms;
    animation: Select-animation-fadeIn 200ms;
    color: #cccccc;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 46px;
}
.Select-clear-zone:hover {
    color: #474646;
}
.Select-clear {
    display: inline-block;
    font-size: 24px;
}
.Select--multi .Select-clear-zone {
    width: 17px;
}
.Select-arrow-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 32px;
    color: #cccccc;
    padding-right: 5px;
}
.Select-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
}
.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
    border-top-color: #666;
}
@-webkit-keyframes Select-animation-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes Select-animation-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.Select-menu-outer {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0 1px 0 #0000000f;
    box-sizing: border-box;
    margin-top: 4px;
    position: absolute;
    font-size: 16px;
    top: 100%;
    width: 100%;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
}
.Select-menu {
    max-height: 198px;
    overflow-y: auto;
}
.Select-option {
    box-sizing: border-box;
    background-color: #fff;
    color: #666666;
    cursor: pointer;
    display: block;
}
.VirtualizedSelectFocusedOption {
    background-color: #f2f2f2;
    cursor: pointer;
}
.VirtualizedSelectOption {
    padding: 5px 0px 5px 10px;
}
.Select-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.Select-option.is-focused {
    background-color: #007eff14;
    color: #333;
}
.Select-option.is-disabled {
    color: #cccccc;
    cursor: default;
}
.Select-noresults {
    box-sizing: border-box;
    color: #999999;
    cursor: default;
    display: block;
}
.Select--multi .Select-input {
    vertical-align: middle;
    margin-left: 10px;
    padding: 0;
}
.Select--multi.has-value .Select-input {
    margin-left: 5px;
}
.Select--multi .Select-value {
    background-color: #007eff14;
    border-radius: 4px;
    border: 1px solid;
    display: inline-block;
    font-size: 0.9em;
    height: 56px;
    margin-left: 5px;
    margin-top: 5px;
    vertical-align: top;
}
.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
    display: inline-block;
    vertical-align: middle;
}
.Select--multi .Select-value-label {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    cursor: default;
    padding: 2px 5px;
}
.Select--multi a.Select-value-label {
    cursor: pointer;
    text-decoration: none;
}
.Select--multi a.Select-value-label:hover {
    text-decoration: underline;
}
.Select--multi .Select-value-icon {
    cursor: pointer;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    border-right: 1px solid #007eff3d;
    padding: 1px 5px 3px;
}
.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
    background-color: #0071e614;
}
.Select--multi .Select-value-icon:active {
    background-color: #007eff3d;
}
.Select--multi.is-disabled .Select-value {
    background-color: #fcfcfc;
    border: 1px solid #e3e3e3;
    color: #333;
}
.Select--multi.is-disabled .Select-value-icon {
    cursor: not-allowed;
    border-right: 1px solid #e3e3e3;
}
.Select--multi.is-disabled .Select-value-icon:hover,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:active {
    background-color: #fcfcfc;
}
@keyframes Select-animation-spin {
    to {
        transform: rotate(1turn);
    }
}
@-webkit-keyframes Select-animation-spin {
    to {
        -webkit-transform: rotate(1turn);
    }
}

.Grid {
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: transform;
}

.Grid__innerScrollContainer {
    box-sizing: border-box;
    overflow: hidden;
}

.Grid__cell {
    position: absolute;
}

.FlexTable__Grid {
    overflow-x: hidden;
}

.FlexTable__headerRow {
    font-weight: 700;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}
.FlexTable__headerTruncatedText {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.FlexTable__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}

.FlexTable__headerColumn,
.FlexTable__rowColumn {
    margin-right: 10px;
    min-width: 0px;
}

.FlexTable__headerColumn:first-of-type,
.FlexTable__rowColumn:first-of-type {
    margin-left: 10px;
}
.FlexTable__headerColumn {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow: hidden;
}
.FlexTable__sortableHeaderColumn {
    cursor: pointer;
}
.FlexTable__rowColumn {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 100%;
}

.FlexTable__sortableHeaderIconContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.FlexTable__sortableHeaderIcon {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 24px;
    -ms-flex: 0 0 24px;
    flex: 0 0 24px;
    height: 1em;
    width: 1em;
    fill: currentColor;
}

.FlexTable__truncatedColumnText {
    text-overflow: ellipsis;
    overflow: hidden;
}

.VirtualScroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.VirtualScroll__innerScrollContainer {
    box-sizing: border-box;
    overflow: hidden;
}

.VirtualScroll__row {
    position: absolute;
}
