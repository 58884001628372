.sliding {
    animation: sliding 6s linear;
}

@keyframes sliding {
    from {
        margin-right: 100%;
        width: 0%;
    }
    to {
        margin-right: 0%;
        width: 100%;
    }
}

.percentage::after {
    animation: percentage 6s linear;
    content: '100% Complete';
}

@keyframes percentage {
    @for $i from 0 through 100 {
        $percent: 0% + $i;
        #{$percent} {
            content: '#{$i}% Complete';
        }
    }
}

.MuiChip-root .MuiChip-label {
    width: 190px;
    text-align: center;
}
